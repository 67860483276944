<!-- 文章列表 -->
<template>
    <div id="goods-box">
        <h3>文章管理</h3>
        <el-table :data="WenZhangList" :cell-style="{ padding: '2px 0' }" height="634" border stripe>
            <el-table-column prop="id" label="id" align="center" width="100"></el-table-column>
            <el-table-column prop="title" label="标题" align="center" width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="title" label="内容" align="center" width="280" show-overflow-tooltip></el-table-column>
            <el-table-column label="显示" width="200" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_show == 1"> 是 </span>
                    <span v-if="scope.row.is_show == 2"> 否 </span>
                </template>
            </el-table-column>
            <el-table-column prop="kind" label="类别" align="center" width="180"></el-table-column>
            <el-table-column prop="create_time" label="创建时间" align="center" width="180"></el-table-column>

            <el-table-column label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-button @click="toUpdateWz(scope.row)" type="primary">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getForm } from '../tools/tool'

export default {
    name: "GoodsListView",
    data() {
        return {
            WenZhangList: [],
        }
    },
    created() {
        getForm('/wz/list')
            .then(data => {
                this.WenZhangList = data.data
                console.log('WenZhangList:', this.WenZhangList)
            })
    },
    mounted() { },
    methods: {
        toUpdateWz(item) {

            this.$router.push({
                path: "/wenzhang/one",
                query: { wz_id: item.id }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.upPicSty {
    width: 50px;
    height: 50px;
}
</style>